import M$plugin$Information from './facade/js/information';
import M$control$InformationControl from './facade/js/informationcontrol';
import M$impl$control$InformationControl from './impl/ol/js/informationcontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.Information = M$plugin$Information;
window.M.control.InformationControl = M$control$InformationControl;
window.M.impl.control.InformationControl = M$impl$control$InformationControl;
